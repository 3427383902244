// import { useMemo } from 'react';
import React from "react";
import {
  TableStyle,
  WrapperTable,
} from "./styled";
import { useTranslation } from "react-i18next";

function TableResult({
  dataSource,
  columns,
  selected,
  onChange,
  height = "auto",
  titleTable,
  loading,
  ...rest
}) {
  const [t] = useTranslation('student');

  const COLUMN_DATA_RESULT_LESSON_TEST = [
    {
      title: t("scheduled_course"),
      key: "受講予定講座",
      dataIndex: "受講予定講座",
      children: [
        {
          title: "番号",
          dataIndex: "科目",
          key: "科目",
          align: "center",
          render: (text, record, index) => (
            <div className="print_css">
              <span>{index + 1}</span>
            </div>
          ),
        },
        {
          title: t("subject"),
          dataIndex: "科目",
          key: "科目",
          align: "center",
          render: (text, record, index) => (
            <div className="print_css">
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("film_attachment"),
          dataIndex: "映・添",
          key: "映・添",
          align: "center",
          render: (text, record, index) => (
            <div className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}>
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("content"),
          dataIndex: "内容",
          key: "内容",
          align: "center",
          render: (text, record, index) => (
            <div className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}>
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("course_name"),
          dataIndex: "講座名",
          key: "講座名",
          align: "center",
          render: (tags, record, index) => (
            <div
              className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}
              style={{
                textAlign: "left",
                paddingLeft: "4px"
              }}
            >
              {tags}
            </div>
          )
        },
        {
          title: t("answer_correction"),
          dataIndex: "答案添削",
          key: "答案添削",
          align: "center",
          render: (tags,record) => (
            record?.["映・添"] !== "添削" &&
              <div className="print_css" style={{background: `${tags === "なし" ? "#FFF" : "#3da7fd"}`}}>
                <span>{tags}</span>
              </div>
          ),
        },
        {
          title: t("total_number_classes"),
          dataIndex: "総講数",
          key: "総講数",
          align: "center",
          render: (text, record, index) => (
            <div className="print_css">
              <span>{ record?.["映・添"] === "添削" ? "" : text }</span>
            </div>
          ),
        },
        {
          title: t("CL/H"),
          dataIndex: "CL / h",
          key: "CL / h",
          align: "center",
          render: (text, record, index) => (
            <div className="print_css">
              <span>{record?.["映・添"] === "添削" ? "" : text}</span>
            </div>
          ),
        },
        {
          title: t("required_time"),
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          render: (text, record, index) => (
            <div className="print_css">
              <span>{ record?.["映・添"] === "添削" ? "" : text }</span>
            </div>
          ),
        },
      ],
    },

  ];

  return (
    <WrapperTable height={height}>
      <TableStyle
        className="antd_test"
        dataSource={dataSource}
        columns={COLUMN_DATA_RESULT_LESSON_TEST}
        pagination={false}
        onChange={onChange}
        bordered
        loading={loading}
        locale={{ emptyText:t("no_data_course")}}
        {...rest}
      />
    </WrapperTable>
  );
}
export default TableResult;
