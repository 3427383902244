/* eslint-disable react-hooks/exhaustive-deps */
import { Notification } from "Components";
import React, { useEffect, useMemo, useState } from "react";
import {
  ModalComponentWrapper,
  TableStyle,
  BorderedRow,
  SelectStyle,
  GroupButton,
} from "./styled";
import { Col, Row } from "antd";
import { FailureBtn, NormalBtn, SuccessBtn } from "Components/Button";
import actions from "Store/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { getCourseFilter, addCourse } = actions;

const ModalAddCourse = ({
  visibleModal,
  onOk,
  onCancel,
  listNumberOrder,
  listScheduleCourse,
  currentStudentInformation,
  // setSelectedRowKeysCourse,
  // setSelectedDataDelete,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation('student');
  const { typeName, subject, typeCourse, courseFilter, loadingCourseFilter } = useSelector(
    (state) => state.student
  );
  const [paramsFilterCourse, setParamsFilterCourse] = useState({
    subject: "",
    method: "",
    type_course:"",
    student_id: currentStudentInformation.studentID
  });
  const [courseChecked, setCourseChecked] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const COLUMN_DATA = [
    {
      title: t("student:subject"),
      dataIndex: "科目",
      key: "科目",
      width: "58px",
      align: "center",
    },
    {
      title: t("student:film_attachment"),
      dataIndex: "映・添",
      key: "映・添",
      width: "58px",
      align: "center",
    },
    {
      title: '種別',
      dataIndex: "種別",
      key: "種別",
      width: "58px",
      align: "center",
    },
    {
      title: t("student:content"),
      dataIndex: "内容",
      key: "内容",
      width: "58px",
      align: "center",
    },
    {
      title: t("student:course_name"),
      dataIndex: [`講座名`],
      key: "講座名",
      width: "280px",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setCourseChecked(selectedRows);
    },
    selectedRowKeys,
  };

  const handleCancel = () => {
    onCancel();
    setSelectedRowKeys([]);
    setCourseChecked([]);
  }

  const handleChange = (value, option) => {
    dispatch(
      getCourseFilter(
        {
          ...paramsFilterCourse,
          [`${option.item}`]: value,
        },
        (action, res) => {}
      )
    );
    setParamsFilterCourse({
      ...paramsFilterCourse,
      [`${option.item}`]: value,
    });
    setSelectedRowKeys([]);
    setCourseChecked([]);
  };

  const addCourseToSchedule = (location) => {
    dispatch(addCourse({ courseChecked, location }, (action, res) => {}));
    //close modal
    onCancel();
    Notification.success(t("message.add_course_success"));
    setCourseChecked([]);
    setSelectedRowKeys([]);
  };

  const listTypeName = useMemo(() => typeName, [typeName]);
  const listSubject = useMemo(() => subject, [subject]);
  const listTypeCourse = useMemo(() => typeCourse, [typeCourse]);


  const selectRow = (record) => {
    const selectedNewRowKeys = [...selectedRowKeys];
    const selectedNewCoursechecked = [...courseChecked];
    if (selectedNewRowKeys.indexOf(record.key) >= 0) {
      selectedNewRowKeys.splice(selectedNewRowKeys.indexOf(record.key), 1);
    } else {
      selectedNewRowKeys.push(record.key);
    }
    const test = selectedNewCoursechecked.some((select) => {
      return select.key === record.key
    })
    if (!test) {
      selectedNewCoursechecked.push(record);
    } else {
      for (let i = 0; i < selectedNewCoursechecked.length; i++) {
        if (selectedNewCoursechecked[i].key === record.key) {
          selectedNewCoursechecked.splice(i, 1);
          break;
        }
      }
    }
    setSelectedRowKeys(selectedNewRowKeys)
    setCourseChecked(selectedNewCoursechecked)
  };

  useEffect(() => {
    if (listTypeName.length && visibleModal) {
      dispatch(
        getCourseFilter(
          {
            subject: listSubject[0].value,
            method: listTypeName[0].value,
            type_course: listTypeCourse[0].value,
            student_id: currentStudentInformation.studentID,
          },
          (action, res) => {
            setParamsFilterCourse({
              subject: listSubject[0].value,
              method: listTypeName[0].value,
              type_course: listTypeCourse[0].value,
              student_id: currentStudentInformation.studentID,
            });
          }
        )
      );
    }
    if (visibleModal === false) {
      setSelectedRowKeys([]);
    }
  }, [visibleModal]);

  return (
    <ModalComponentWrapper
      open={visibleModal}
      title={t("add_new_course")}
      onOk={onOk}
      onCancel={handleCancel}
      destroyOnClose
      centered
    >
      <Row>{t("course_list")}</Row>
      <BorderedRow>
        <Col span={24}>
          <SelectStyle
            style={{ width: 90 }}
            options={subject}
            defaultValue={subject[0]}
            onChange={handleChange}
          />
          <SelectStyle
            style={{ width: 90 }}
            options={typeName}
            defaultValue={typeName[0]}
            onChange={handleChange}
          />
          <SelectStyle
            style={{ width: 90 }}
            options={typeCourse}
            defaultValue={typeCourse[0]}
            onChange={handleChange}
          />
        </Col>
        <Col span={24}>
          <TableStyle
            scroll={{ x: "100%", y: 400 }}
            dataSource={courseFilter}
            className={courseFilter.length > 0 ? "" : "table_nodata"}
            columns={COLUMN_DATA}
            pagination={false}
            bordered
            rowSelection={{ ...rowSelection }}
            loading={loadingCourseFilter}
				    locale={{ emptyText:t("no_data")}}
            onRow={(record) => ({
              onClick: () => {
                selectRow(record);
              }
            })}
            {...rest}
          />
        </Col>
      </BorderedRow>

      <Row justify={"space-around"} className="footer_btn">
        <Col span={24} lg={12}>
          <NormalBtn onClick={handleCancel}>キャンセル </NormalBtn>
        </Col>
        <GroupButton span={24} lg={12}>
          <SuccessBtn
            disabled={!courseChecked.length || loadingCourseFilter}
            onClick={() => addCourseToSchedule("BOTTOM")}
          >
            {t("add_bottom")}
          </SuccessBtn>
          <div style={{ width: "2rem" }}></div>
          <FailureBtn
            disabled={!courseChecked.length || loadingCourseFilter}
            onClick={() => addCourseToSchedule("TOP")}
          >
            {t("add_top")}{" "}
          </FailureBtn>
        </GroupButton>
      </Row>
    </ModalComponentWrapper>
  );
};

export default ModalAddCourse;
