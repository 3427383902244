import React, { useEffect, useState } from "react";
import {
  WrapPrint,
  HeaderPrintExcel,
  ContentPrintExcel,
  WrapCommentCalculator,
  ContentCalculator,
  CommentWrapper
} from "./styled";
import dayjs from "dayjs";
import { mergeTwoArray } from "Utils/convertValue";
const HeaderPrint = ({currentStudentInformation, informationPrint, dataSource, teacherCommentValue}) => {
  const [sumCourse, setSumcourse] = useState(0)
  const [totalCourseTutoring, setTotalCourseTutoring] = useState(0)
  const [selectScience, setSelectScience] = useState([])
  const [selectSocial, setSelectSocial] = useState([])

  useEffect(() => {
    const sumOfCourse = dataSource.reduce((total, current) => {
      return total + (current?.必要時限 && current?.["映・添"] !== "添削" ? Number(current?.必要時限) : 0)
    }, 0);
    const sumCourseTutoring = dataSource.filter((data) => {
      return data?.["映・添"] === "添削"
    });
    setSumcourse(sumOfCourse)
    setTotalCourseTutoring(sumCourseTutoring.length)
  }, [dataSource])

 
  useEffect(() => {
    const selectScience1 = !!currentStudentInformation?.selectedContent && currentStudentInformation?.selectedContent?.["二次・個別"]?.["理科"] ? currentStudentInformation?.selectedContent?.["二次・個別"]?.["理科"] : []
    const selectScience2 = !!currentStudentInformation?.selectedContent && currentStudentInformation?.selectedContent?.["共通テスト"]?.["理科"] ? currentStudentInformation?.selectedContent?.["共通テスト"]?.["理科"] : []
    
    const mergeScience =
      (Array.isArray(selectScience1) && Array.isArray(selectScience2)) ?
        mergeTwoArray(selectScience1, selectScience2).filter(value => !["総合", "対策"]?.includes(value))
      : mergeTwoArray(Object.keys(selectScience1), Object.keys(selectScience2)).filter(value => !["総合", "対策"]?.includes(value) && !value.includes('empty'));
    const selectSocial1 = !!currentStudentInformation?.selectedContent && currentStudentInformation?.selectedContent?.["二次・個別"]?.["社会"] ? currentStudentInformation?.selectedContent?.["二次・個別"]?.["社会"] : []
    const selectSocial2 = !!currentStudentInformation?.selectedContent && currentStudentInformation?.selectedContent?.["共通テスト"]?.["社会"] ? currentStudentInformation?.selectedContent?.["共通テスト"]?.["社会"] : []

    const mergeSocial =
      (Array.isArray(selectSocial1) && Array.isArray(selectSocial2) ) ?
        mergeTwoArray(selectSocial1, selectSocial2).filter(value => !["総合", "対策"]?.includes(value))
      : mergeTwoArray(Object.keys(selectSocial1), Object.keys(selectSocial2)).filter(value => !["総合", "対策"]?.includes(value) && !value.includes('empty'));
    
    setSelectScience(mergeScience)
    setSelectSocial(mergeSocial)
  }, [currentStudentInformation])
  
  return (
    <WrapPrint>
      <HeaderPrintExcel>
        <div className="header_title">＠willハイスクール　年間学習カリキュラム</div>
        <div className="header_time">
          <span>受講期間</span>
          <span>{`${dayjs(currentStudentInformation[`startTime`]).format('YYYY/MM/DD') || ""} ~ ${dayjs(currentStudentInformation[`endTime`]).format('YYYY/MM/DD') || ""}`}</span>
        </div>
      </HeaderPrintExcel>
      <ContentPrintExcel>
        <tbody>
          <tr>
            <td>ＩＤ</td>
            <td>{currentStudentInformation['studentID']}</td>
            <td>高校</td>
            <td>{informationPrint.highSchoolName}</td>
            <td>学年</td>
            <td>{informationPrint.collegeYear}</td>
            <td>理科</td>
            <td>{selectScience?.join("、")}</td>
          </tr>
          <tr>
            <td>生徒氏名</td>
            <td>{currentStudentInformation['studentName']}</td>
            <td>部活</td>
            <td>{informationPrint.club}</td>
            <td>文理</td>
            <td>{currentStudentInformation['socialScience']}</td>
            <td>社会</td>
            <td>{selectSocial?.join("、")}</td>
          </tr>
          <tr>
            <td>志望 大学</td>
            <td>{currentStudentInformation['universityName']}</td>
            <td>学部</td>
            <td>{currentStudentInformation['departmentName']}</td>
            <td>学科 専攻</td>
            <td>{currentStudentInformation['majorName']}</td>
            <td>日程 方式</td>
            <td>{currentStudentInformation['scheduleMethod']}</td>
          </tr>
        </tbody>
      </ContentPrintExcel>
      {/* <ContentPrintExcel>
        <tfoot>
          <tr>
            <th rowSpan={2} >有効時限</th>
            <th>登録数</th>
            <th>消化数（欠席数）</th>
            <th>未消化数</th>
            <th>余剰数</th>
            <th>最終時限日</th>
            <th>答案添削</th>
          </tr>
          <tr>
            <td>{informationPrint.registrationNumber}</td>
            <td>{informationPrint.digestionNumber}</td>
            <td>{informationPrint.undigestedNumber}</td>
            <td>{informationPrint.surplusNumber}</td>
            <td>{informationPrint.last_deadline_date}</td>
            <td></td>
          </tr>
        </tfoot>
      </ContentPrintExcel> */}
      <WrapCommentCalculator>
        <CommentWrapper>
          <div className="comment_title">
            <span>備考</span>
            <span>{teacherCommentValue || ""}</span>
          </div>
        </CommentWrapper>
        <ContentCalculator>
          <div className="calculator_top">
            <div>映像</div>
            <div>合計</div>
            <div>{sumCourse}</div>
            <div>時限</div>
          </div>
          <div className="calculator_bottom">
            <div>添削</div>
            <div>合計</div>
            <div>{totalCourseTutoring}</div>
            <div>講座</div>
          </div>
        </ContentCalculator>
      </WrapCommentCalculator>
    </WrapPrint>
  )
}

export default HeaderPrint