import { Table } from 'antd';
import styled from 'styled-components';

export const WrapPrint = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 8px;
`;

export const HeaderPrintExcel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;

  .header_title {
    width: 58%;
    background: #FED458;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }

  .header_time {

    span:first-child {
      font-weight: 600;
      padding: 5px;
      text-align: center;
      border: 1px solid #000;
      width: 25%;
      font-size: 14px;
    }
    span:last-child {
      width: 75%;
      font-weight: 600;
      font-size: 14px;
      padding: 5px;
      text-align: center;
      border: 1px solid #000;
    }
  }
`

export const ContentPrintExcel = styled.table`
  width: 100%;
  border: 1px solid black;
  
  th, td {
    border: 1px solid black;
    font-size: 10px;
    font-weight: 600;
    padding: 6px 4px;
    text-align: center;
    vertical-align: middle;
  }

  tbody {
    tr {
      td:first-child {
        width: 8.1%;
        background-color: rgb(221 221 221);
      }
      td:nth-child(3),
      td:nth-child(5),
      td:nth-child(7) {
        width: 6%;
        background-color: rgb(221 221 221);
      }
      td:nth-child(2) {
        width: 25%;
      }
      td:nth-child(4) {
        width: 19%;
      }
      td:nth-child(6) {
        width: 18%;
      }
      td:nth-child(8) {
        width: 15%;
      }
    }
  }

  tfoot {
    tr {
      th:first-child {
        width: 8.1%;
        background-color: rgb(221 221 221);
      }
      th:nth-child(2),
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(5),
      th:nth-child(6) {
        width: 12.5%;
        background-color: rgb(221 221 221);
      }
      th:nth-child(7) {
        background-color: rgb(221 221 221);
      }
    }
  }
`

export const WrapCommentCalculator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`

export const CommentWrapper = styled.div`
  width: 58%;
  display: flex;
  align-items: center;

  .comment_title {
    width: 100%;
    display: flex;
    align-items: center;

    span:first-child {
      font-weight: 600;
      min-height: 40px;
      padding: 10px 4px;
      text-align: center;
      border: 1px solid #000;
      width: 16%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(221 221 221);
    }
    span:last-child {
      width: 100%;
      min-height: 40px;
      font-size: 12px;
      padding: 10px 8px;
      border: 1px solid #000;
      border-left: 0;
      display: flex;
      align-items: center;
    }
  }
`

export const ContentCalculator = styled.div`
  width: 30%;
  border: 1px solid #000;
  
  .calculator_top,
  .calculator_bottom {
    display: flex;
    align-items: center;
    width: 100%;

    div {
      width: 25%;
      padding: 4px;
      text-align: center;
      font-weight: 600;
    }
  }

  .calculator_top {
    border-bottom: 1px solid #000;
    background-color: #F5E390;
  }

  .calculator_bottom {
    background-color: #EFBAA9;
  }
`

export const WrapperTable = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.white};
	height: ${({ height }) => height};
	border-radius: 1.75rem;
	margin: 1rem 0 4rem 0;
  .no_data {
    .ant-table-placeholder {
      .ant-table-cell {
        height: 80px;
      }
    }
  }
`;

export const TableStyle = styled(Table)`
	height: 100%;
	display: block;
	overflow-x: auto;
	white-space: nowrap;
  
  .ant-table-container {
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-table-thead{
    tr {
      .ant-table-cell {
        padding: 2px 0 !important;
        font-size: 12px;
      }
    }
  }

  .ant-table-tbody{
    tr {
      .ant-table-cell {
        padding: 0 !important;
        font-size: 11px;
      }
      /* .ant-table-cell:nth-child(6) {
        background-color: #3da7fd;
      } */
    }
  }

	@media only screen and (max-height: 600px) {
		.ant-table-container {
    	border-inline-end: 1px solid #f0f0f0;
    }
	}

  thead{
    display: page-break-inside;
  }

  .ant-table-thead{
	
    tr:first-child{
      .ant-table-cell:first-child {
        border-right: 0 !important; 
      }

      .ant-table-cell:nth-child(4),
      .ant-table-cell:nth-child(5),
      .ant-table-cell:nth-child(6),
      .ant-table-cell:nth-child(7),
      .ant-table-cell:nth-child(8),
      .ant-table-cell:nth-child(9),
      .ant-table-cell:nth-child(10),
      .ant-table-cell:nth-child(11),
      .ant-table-cell:nth-child(12),
      .ant-table-cell:nth-child(13),
      .ant-table-cell:nth-child(14),
      .ant-table-cell:nth-child(15),
      .ant-table-cell:nth-child(16),
      .ant-table-cell:nth-child(17) {
        padding: 0;
      }
			.ant-table-cell:nth-child(1){
				background: #FED458;
        padding: 4px 0px;
        font-weight: 600;
			}
    }

    tr:nth-child(2) {
      .ant-table-cell {
        padding: 0;
        font-size: 12px;
      }
    }
  }

	.supplementary {
		background: #00bfff;
		line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	}

	.not_supplement {
		line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	}

  .print_css {
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;