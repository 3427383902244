import React from "react";
import {
  ActionButton,
  ModalComponentWrapper,
  ButtonCancel,
  ButtonOK,
  TitleAlert,
} from "./styled";


const ModalAlert = ({ onOk, Title , onCancel , titleOk, titleCancel, visibleModal}) => {
  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
      // title={t("update_course")}
      className='modal__import'
    >
      <TitleAlert>{Title}</TitleAlert>
      <ActionButton>
        <ButtonOK onClick={onOk}>{titleOk}</ButtonOK>
        <ButtonCancel onClick={onCancel}>{titleCancel}</ButtonCancel>
      </ActionButton>
    </ModalComponentWrapper>
  );
};

export default ModalAlert;
